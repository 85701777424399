import type {
  AbstractMesh,
  AssetContainer,
  DefaultRenderingPipeline,
  DynamicTexture,
  LensRenderingPipeline,
  PBRMaterial,
  Scene,
} from '@babylonjs/core';
import type { PartialWithUndefined } from '@orangelv/utils';
import type { fabric as fabricLibrary } from 'fabric';

type State = {
  setIsCoverCanvasActive: (isCoverCanvasActive: boolean) => void;
  isDigesting: boolean;
  isReady: boolean;
  isGeneratingPreviews: boolean;
  canvasRef: Ref<HTMLCanvasElement | null>;
  coverCanvasRef: Ref<HTMLCanvasElement | null>;
  fabricCanvasesRef: Ref<Record<string, HTMLCanvasElement | null>>;
  props: Props;
  models: Record<string, ModelState>;
} & PartialWithUndefined<{
  previousProps: Props;
  scene: Scene;
  emptyScene: Scene;
  defaultRenderingPipeline: DefaultRenderingPipeline;
  lensRenderingPipeline: LensRenderingPipeline;
}>;

type ModelState = {
  url: string;
  assetContainer: AssetContainer;
  rootMesh: AbstractMesh;
  allMeshes: AbstractMesh[];
  originalMaterials: Record<string, PBRMaterial>;
  materialKeys: Record<string, string>;
  dynamicTextures: Record<
    string,
    PartialWithUndefined<{
      [textureKey in keyof MaterialConfigTextures]: DynamicTexture;
    }>
  >;
  fabrics: ModelFabrics;
};

type Props = {
  config: RendererConfig;
} & PartialWithUndefined<{
  onLoading: (isLoading: boolean) => void;
  onCameras: (cameras: Cameras, modelId: string) => void;
  onCameraReset: () => void;
  onSetCamera: (setCamera: SetCamera) => void;
  onMeshIds: (meshIds: readonly string[], modelId: string) => void;
  onMaterialIds: (materialIds: readonly string[], modelId: string) => void;
  onMeshPicked: (meshId: string, modelId: string) => void;
  onGeneratePreviews: (generatePreviews: GeneratePreviews) => void;
  onLog: (level: LogLevel, message: string) => void;
  onLogGroup: (starts: boolean) => void;
  isHidden: boolean;
  // Changing this to anything else will auto-resize the scene.
  autoResize: unknown;
}>;

type RendererConfig = {
  models: Record<string, ModelConfig | undefined>;
} & PartialWithUndefined<{
  scene: SceneConfig;
  camera: CameraConfig;
  onAfterSceneCreated: (state: State) => void;
  onAfterModelLoaded: (state: State) => void;
  onAfterMaterialsUpdated: (state: State) => void;
}>;

type SceneConfig = PartialWithUndefined<{
  backgroundColor: string;
  environmentTexture: string | string[];
  environmentRotation: number;
  environmentIntensity: number;
  skyboxTexture: string | readonly string[];
  skyboxBlur: number;
  hdrSize: number;
  toneMapping: 'standard' | 'aces' | 'khr';
  materialContrast: number;
  materialExposure: number;
  antiAliasing:
    | {
        msaa: number;
      }
    | {
        fxaa: boolean;
      };
  chromaticAberration: {
    amount: number;
    intensity: number;
  };
  grain: {
    intensity: number;
  } & PartialWithUndefined<{ animated: boolean }>;
  edgeBlur: number;
  darkenOutOfFocus: number;
}>;

type CameraConfig = PartialWithUndefined<{
  defaultCamera: string | [cameraId: string, modelId: string];
  defaultAlpha: number;
  defaultBeta: number;
  defaultRadius: number;
  lowerBeta: number;
  upperBeta: number;
  lowerRadius: number;
  upperRadius: number;
  minZ: number;
  wheelPrecision: number;
  pinchPrecision: number;
  peek: PartialWithUndefined<{
    xFactor: number;
    yFactor: number;
  }>;
  autoRotate: boolean;
  /** Moves the image by `x` pixels to the right and by `y` pixels up, without
  disturbing the camera in any way. Just as if the canvas were offset. */
  translateProjection: PartialWithUndefined<{
    x: number;
    y: number;
  }>;
}>;

type PositionAbsolute = PartialWithUndefined<{
  x: number;
  y: number;
  z: number;
}>;

type PositionRelative = {
  /** Model ID */
  below: string;
} & PartialWithUndefined<{
  offset: number;
}>;

type Position = PositionAbsolute | PositionRelative;

type ModelConfig = {
  url: string;
} & PartialWithUndefined<{
  dependsOn: string;
  getExternalAssetFilename: (filename: string) => string;
  defaultPosition: Position;
  meshes: Record<string, MeshConfig>;
  materials: Record<string, MaterialConfig>;
}>;

type MeshConfig = PartialWithUndefined<{
  isVisible: boolean;
  isPickable: boolean;
  scaling: PartialWithUndefined<{ x: number; y: number; z: number }>;
  materialId: string;
}>;

type MaterialConfigBase = PartialWithUndefined<{
  materialId: string;
  metallic: number;
  roughness: number;
  flipX: boolean;
  flipY: boolean;
}>;

type MaterialConfig =
  | (MaterialConfigBase & MaterialConfigColors & MaterialConfigTextures)
  | undefined;

type MaterialConfigTextures = PartialWithUndefined<{
  diffuseTexture: MaterialPropertyTexture;
  specularTexture: MaterialPropertyTexture;
  emissiveTexture: MaterialPropertyTexture;
  ambientTexture: MaterialPropertyTexture;
  normalTexture: MaterialPropertyTexture;
}>;

type MaterialConfigColors = PartialWithUndefined<{
  diffuseColor: string;
  specularColor: string;
  emissiveColor: string;
  ambientColor: string;
}>;

type MaterialPropertyRemove = { remove: true };

type MaterialPropertyUrl = { url: string };

type MaterialPropertyCanvas = {
  getCanvas: (canvas: HTMLCanvasElement, isInit?: boolean) => Promise<unknown>;
  key: unknown;
};

type MaterialPropertyFabric = {
  getFabric: GetFabric;
  key: unknown;
};

type GetFabric = (
  fabric: fabricLibrary.Canvas,
  isInit: boolean,
  getFabrics: () => ModelFabrics,
) => Promise<void>;

type MaterialPropertyTexture =
  | MaterialPropertyRemove
  | MaterialPropertyUrl
  | MaterialPropertyCanvas
  | MaterialPropertyFabric;

type MaterialProperty = string | MaterialPropertyTexture;

type ModelFabrics = Record<string, fabricLibrary.Canvas>;

type Camera = { id: string; name: string };
type Cameras = Camera[];

type SetCamera = (cameraId: string, modelId?: string) => void;

type GeneratePreviews = (
  options?: PartialWithUndefined<{
    cameraIds: readonly string[];
    size: Size;
    rendererConfig: RendererConfig;
  }>,
) => Promise<Record<string, Blob>>;

type Size = { width: number; height: number };

enum LogLevel {
  Verbose = 'verbose',
  Info = 'info',
}

type Ref<T> = {
  current: T;
};

export { LogLevel };
export type {
  Camera,
  CameraConfig,
  Cameras,
  GeneratePreviews,
  GetFabric,
  MaterialConfig,
  MaterialConfigColors,
  MaterialConfigTextures,
  MaterialProperty,
  MaterialPropertyCanvas,
  MaterialPropertyFabric,
  MaterialPropertyRemove,
  MaterialPropertyUrl,
  MeshConfig,
  ModelConfig,
  ModelFabrics,
  ModelState,
  Position,
  PositionAbsolute,
  PositionRelative,
  Props,
  Ref,
  RendererConfig,
  SceneConfig,
  SetCamera,
  Size,
  State,
};
