import {
  Color4,
  Engine,
  type EngineOptions,
  HemisphericLight,
  ImageProcessingConfiguration,
  Scene,
  Vector3,
} from '@babylonjs/core';

import packageJson from '../package.json';
import addDebugProperties from './add-debug-properties.js';
import { normalizeColor } from './colors.js';
import generatePreviews from './generate-previews.js';
import { getState, setScenes } from './state.js';
import {
  LogLevel,
  type Ref,
  type RendererConfig,
  type State,
} from './types.js';
import { loadEnvironmentTexture } from './utils.js';

const ENGINE_OPTIONS: EngineOptions = {
  limitDeviceRatio: 3,
  doNotHandleTouchAction: true,
  alpha: true,
  preserveDrawingBuffer: true,
};
const ADAPT_TO_DEVICE_RATIO = true;

const createScene =
  (stateRef: Ref<State>) =>
  async (config: RendererConfig): Promise<void> => {
    const sceneConfig = config.scene ?? {};

    const { props, canvasRef } = getState(stateRef);

    const canvas = canvasRef.current;

    if (props.onLog) {
      props.onLog(
        LogLevel.Info,
        `Launching ${packageJson.name} (${packageJson.version})`,
      );
    }

    const engine = new Engine(
      canvas,
      true, // Anti-alias is also controlled in createPipeline function.
      ENGINE_OPTIONS,
      ADAPT_TO_DEVICE_RATIO,
    );

    const scene = new Scene(engine);

    // A simple way to let Playwright know when it can proceed
    scene.onAfterRenderObservable.addOnce(() => {
      document.documentElement.dataset['bjsRendered'] = '';
    });

    const emptyScene = new Scene(engine);

    setScenes(stateRef)(scene, emptyScene);

    addDebugProperties(stateRef);

    scene.useRightHandedSystem = true;

    if (sceneConfig.backgroundColor === undefined) {
      scene.clearColor = new Color4(0, 0, 0, 0);
      emptyScene.clearColor = scene.clearColor;
    } else {
      scene.clearColor = normalizeColor(sceneConfig.backgroundColor);
      emptyScene.clearColor = scene.clearColor;
    }

    if (sceneConfig.environmentTexture === undefined) {
      // eslint-disable-next-line no-new -- We need the side effects in this case I guess
      new HemisphericLight('myLight', new Vector3(0, 1, 0), scene);
    } else {
      const texture = await loadEnvironmentTexture(
        scene,
        sceneConfig.environmentTexture,
      );

      scene.environmentTexture = texture;

      if (sceneConfig.environmentRotation !== undefined) {
        texture.rotationY = sceneConfig.environmentRotation;
      }

      if (sceneConfig.environmentIntensity !== undefined) {
        scene.environmentIntensity = sceneConfig.environmentIntensity;
      }
    }

    if (sceneConfig.toneMapping !== undefined) {
      scene.imageProcessingConfiguration.toneMappingEnabled = true;
      const toneMappingTypes = {
        standard: ImageProcessingConfiguration.TONEMAPPING_STANDARD,
        aces: ImageProcessingConfiguration.TONEMAPPING_ACES,
        khr: ImageProcessingConfiguration.TONEMAPPING_KHR_PBR_NEUTRAL,
      };

      scene.imageProcessingConfiguration.toneMappingType =
        toneMappingTypes[sceneConfig.toneMapping];
    }

    if (sceneConfig.materialContrast !== undefined) {
      scene.imageProcessingConfiguration.contrast =
        sceneConfig.materialContrast;
    }

    if (sceneConfig.materialExposure !== undefined) {
      scene.imageProcessingConfiguration.exposure =
        sceneConfig.materialExposure;
    }

    if (props.onGeneratePreviews) {
      props.onGeneratePreviews(generatePreviews(stateRef));
    }

    if (config.onAfterSceneCreated) {
      config.onAfterSceneCreated(getState(stateRef));
    }
  };

export default createScene;
