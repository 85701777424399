import { SceneLoader } from '@babylonjs/core';
import { GLTFFileLoader } from '@babylonjs/loaders';
import { hasLengthAtLeast, ok } from '@orangelv/utils';

import setCamera from './camera/set-camera.js';
import getAllMeshes from './get-all-meshes.js';
import getFabricMaterialIdsByModelId from './materials/get-fabric-material-ids-by-model-id.js';
import { getState, setModelState } from './state.js';
import { LogLevel, type Ref, type State } from './types.js';

const loadModel =
  (stateRef: Ref<State>) =>
  async (modelId: string): Promise<void> => {
    const { props, scene } = getState(stateRef);

    const modelConfig = props.config.models[modelId];

    if (!modelConfig) {
      return;
    }

    if (props.onLog) {
      props.onLog(LogLevel.Info, `Loading ${modelId} from ${modelConfig.url}`);
    }

    ok(scene);

    const { getExternalAssetFilename } = modelConfig;

    if (getExternalAssetFilename) {
      SceneLoader.OnPluginActivatedObservable.addOnce((loader) => {
        if (!(loader instanceof GLTFFileLoader)) {
          return;
        }

        loader.onParsedObservable.addOnce((data) => {
          const json = data.json as {
            buffers: { uri: string }[];
            images: { uri: string }[];
          };

          for (const buffer of json.buffers) {
            buffer.uri = getExternalAssetFilename(buffer.uri);
          }

          for (const image of json.images) {
            image.uri = getExternalAssetFilename(image.uri);
          }
        });
      });
    }

    const assetContainer = await SceneLoader.LoadAssetContainerAsync(
      modelConfig.url,
      undefined,
      scene,
    );

    ok(hasLengthAtLeast(assetContainer.meshes, 1), `No meshes on ${modelId}!`);

    const rootMesh = assetContainer.meshes[0];

    const allMeshes = getAllMeshes(assetContainer);

    const fabricMaterialIdsByModelId = getFabricMaterialIdsByModelId(
      stateRef,
      props,
    )();

    const materialIdsWithPickableMesh = fabricMaterialIdsByModelId[modelId];

    for (const mesh of allMeshes) {
      const isPickable = modelConfig.meshes?.[mesh.id]?.isPickable ?? true;

      // TODO: [CP-1237] Update when materials change.
      mesh.isPickable =
        isPickable ||
        (!!mesh.material &&
          !!materialIdsWithPickableMesh &&
          materialIdsWithPickableMesh.includes(mesh.material.id));
    }

    setModelState(stateRef)(modelId, {
      url: modelConfig.url,
      assetContainer,
      rootMesh,
      allMeshes,
      originalMaterials: {},
      materialKeys: {},
      dynamicTextures: {},
      fabrics: {},
    });

    assetContainer.addAllToScene();

    if (props.onMeshIds) {
      props.onMeshIds(
        allMeshes.map(({ id }) => id),
        modelId,
      );
    }

    if (props.onMaterialIds) {
      props.onMaterialIds(
        assetContainer.materials.map(({ id }) => id),
        modelId,
      );
    }

    if (props.config.onAfterModelLoaded) {
      props.config.onAfterModelLoaded(getState(stateRef));
    }

    const { cameras } = assetContainer;

    if (props.onCameras && cameras.length > 0) {
      props.onCameras(
        cameras.map((camera) => ({ id: camera.id, name: camera.name })),
        modelId,
      );
    }

    if (props.onSetCamera) {
      props.onSetCamera((cameraId, modelIdInner) => {
        setCamera(stateRef, props)(cameraId, modelIdInner);
      });
    }
  };

export default loadModel;
