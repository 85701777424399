import {
  type ArcRotateCamera,
  type Camera,
  Color4,
  Tools,
} from '@babylonjs/core';
import { ok, until } from '@orangelv/utils';
import { loadImage } from '@orangelv/utils-dom';
import ky from 'ky';

import digest from './digest.js';
import { getState, setGeneratingPreviews, setProps } from './state.js';
import type { GeneratePreviews, Ref, State } from './types.js';

const generatePreviews: (stateRef: Ref<State>) => GeneratePreviews =
  (stateRef) => async (options) => {
    await until(() => {
      const state = getState(stateRef);
      return state.isReady && !state.isDigesting;
    });

    setGeneratingPreviews(stateRef)(true);

    const { props, scene, canvasRef, coverCanvasRef, setIsCoverCanvasActive } =
      getState(stateRef);

    const rendererConfig = options?.rendererConfig ?? props.config;
    const cameraIds = options?.cameraIds ?? [];
    const width = options?.size?.width ?? 1024;
    const height = options?.size?.height ?? 1024;

    ok(scene);

    const engine = scene.getEngine();

    const canvas = canvasRef.current;
    ok(canvas, 'No canvas, is bjs-renderer mounted?');

    const activeCamera = scene.activeCamera as ArcRotateCamera | null;
    ok(activeCamera);

    const coverUrl = await Tools.CreateScreenshotUsingRenderTargetAsync(
      engine,
      activeCamera,
      { width: canvas.width, height: canvas.height },
      'image/png',
      8,
    );
    const coverImage = await loadImage(coverUrl);

    const coverCanvas = coverCanvasRef.current;
    ok(coverCanvas, 'No cover canvas, is bjs-renderer mounted?');
    const coverContext = coverCanvas.getContext('2d');
    ok(coverContext);

    coverCanvas.width = canvas.width;
    coverCanvas.height = canvas.height;

    coverContext.clearRect(0, 0, canvas.width, canvas.height);
    coverContext.drawImage(coverImage, 0, 0);

    setIsCoverCanvasActive(true);

    const snapshotPreview = async (camera: Camera): Promise<Blob> => {
      const state = getState(stateRef);
      setProps(stateRef)(
        {
          ...state.props,
          config: rendererConfig,
        },
        state.props,
      );

      await digest(stateRef);

      const url = await Tools.CreateScreenshotUsingRenderTargetAsync(
        engine,
        camera,
        { width, height },
        'image/png',
        8,
      );

      const response = await ky(url);
      return response.blob();
    };

    const previousColor = scene.clearColor;
    const previousAlpha = activeCamera.alpha;
    const previousBeta = activeCamera.beta;
    const previousRadius = activeCamera.radius;

    scene.clearColor = new Color4(0, 0, 0, 0);

    const blobs: Record<string, Blob> = {};

    if (cameraIds.length === 0) {
      blobs['default'] = await snapshotPreview(activeCamera);
    } else {
      for (const cameraId of cameraIds) {
        const camera = scene.cameras.find(({ id }) => id === cameraId);

        ok(camera, `Camera ${cameraId} not found when generating previews!`);

        // eslint-disable-next-line no-await-in-loop -- This can probably be refactored to use Promise.all(cameraIds.map(...))
        blobs[cameraId] = await snapshotPreview(camera);
      }
    }

    // Render the scene as it was before generating the previews.

    setProps(stateRef)(props, props);
    await digest(stateRef);

    scene.clearColor = previousColor;
    activeCamera.alpha = previousAlpha;
    activeCamera.beta = previousBeta;
    activeCamera.radius = previousRadius;

    setGeneratingPreviews(stateRef)(false);

    setIsCoverCanvasActive(false);

    return blobs;
  };

export default generatePreviews;
