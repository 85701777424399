import { DynamicTexture, type PBRMaterial, Texture } from '@babylonjs/core';
import { ok } from '@orangelv/utils';
import { urlToCanvas } from '@orangelv/utils-dom';

import { getState, setModelState } from '../state.js';
import {
  LogLevel,
  type MaterialConfig,
  type Ref,
  type State,
} from '../types.js';
import {
  isMaterialPropertyCanvas,
  isMaterialPropertyFabric,
  isMaterialPropertyRemove,
  isMaterialPropertyUrl,
} from './checks.js';
import {
  COLOR_PROPERTIES,
  TEXTURE_HEIGHT,
  TEXTURE_PROPERTIES,
  TEXTURE_PROPERTY_TO_BABYLON_PROPERTY,
  TEXTURE_WIDTH,
} from './index.js';
import renderCanvas from './render-canvas.js';
import renderFabric from './render-fabric.js';

const getOriginalMaterialId = (originalId: string): string =>
  `_original.${originalId}`;

const updateMaterial =
  (stateRef: Ref<State>) =>
  async (
    modelId: string,
    materialId: string,
    materialConfig: MaterialConfig,
  ): Promise<void> => {
    if (!materialConfig) {
      // Nothing to do with the material.
      return;
    }

    const state = getState(stateRef);
    const modelState = state.models[modelId];
    if (!modelState) throw new Error('Logic error');

    ok(state.scene);

    const material = state.scene.materials.find(
      ({ id }) => id === materialId,
    ) as PBRMaterial | undefined;

    ok(material, `Material ${materialId} not found on model ${modelId}!`);

    if (state.props.onLog) {
      state.props.onLog(
        LogLevel.Verbose,
        `Updating ${materialId} on ${modelId}`,
      );
    }

    let isMaterialReassignNeeded = false;

    let nextMaterial: PBRMaterial;
    const originalMaterial = modelState.originalMaterials[materialId];
    const boundMeshes = material.getBindedMeshes(); // cspell:disable-line
    if (originalMaterial) {
      nextMaterial = originalMaterial.clone(materialId);

      isMaterialReassignNeeded = true;
    } else {
      modelState.originalMaterials[materialId] = material.clone(
        getOriginalMaterialId(materialId),
      );
      nextMaterial = material;
    }

    if (materialConfig.materialId !== undefined) {
      const targetMaterial = state.scene.materials.find(
        (x) => x.id === materialConfig.materialId,
      ) as PBRMaterial | undefined;

      ok(
        targetMaterial,
        `Material ${materialConfig.materialId} not found on model ${modelId}!`,
      );

      nextMaterial.dispose();
      nextMaterial = targetMaterial.clone(material.id);

      isMaterialReassignNeeded = true;
    }

    if (materialConfig.metallic !== undefined) {
      nextMaterial.metallic = materialConfig.metallic;
    }

    if (materialConfig.roughness !== undefined) {
      nextMaterial.roughness = materialConfig.roughness;
    }

    for (const [propertyKey, propertyFunction] of COLOR_PROPERTIES) {
      const property = materialConfig[propertyKey];
      if (property === undefined) continue;
      propertyFunction(nextMaterial, property);
    }

    const texturePromises = [];

    for (const [propertyKey, propertyFunction] of TEXTURE_PROPERTIES) {
      const property = materialConfig[propertyKey];
      if (!property) continue;

      const materialDynamicTextures =
        modelState.dynamicTextures[materialId] ?? {};

      let dynamicTexture = materialDynamicTextures[propertyKey];
      if (!dynamicTexture) {
        dynamicTexture = new DynamicTexture(
          `${materialId}.${propertyKey}`,
          { width: TEXTURE_WIDTH, height: TEXTURE_HEIGHT },
          state.scene,
          true, // Jagged edges on sharp features otherwise
        );
        dynamicTexture.hasAlpha = true;

        materialDynamicTextures[propertyKey] = dynamicTexture;
      }

      modelState.dynamicTextures[materialId] = materialDynamicTextures;

      // TODO: [CP-1237] Fabric property should be available for any texture,
      // not just for diffuse texture.
      if (
        !isMaterialPropertyFabric(property) &&
        propertyKey === 'diffuseTexture'
      ) {
        const fabric = modelState.fabrics[materialId];
        if (fabric) {
          const modelStateFresh = getState(stateRef).models[modelId];
          if (!modelStateFresh) throw new Error('Logic error');
          fabric.dispose();

          setModelState(stateRef)(modelId, {
            ...modelStateFresh,
            fabrics: Object.fromEntries(
              Object.entries(modelStateFresh.fabrics).filter(
                ([k]) => k !== materialId,
              ),
            ),
          });
        }
      }

      if (isMaterialPropertyRemove(property)) {
        const babylonProperty =
          TEXTURE_PROPERTY_TO_BABYLON_PROPERTY[propertyKey];
        // You might think that we want to dispose the texture, but that would remove other textures as well.
        // eslint-disable-next-line unicorn/no-null -- Probably this property must be null not undefined
        nextMaterial[babylonProperty] = null;
      } else if (isMaterialPropertyUrl(property)) {
        texturePromises.push(
          (async (): Promise<void> => {
            const canvas = dynamicTexture.getContext()
              .canvas as HTMLCanvasElement;
            await urlToCanvas(property.url, { canvas });
            dynamicTexture.update(false);
            await propertyFunction(nextMaterial, dynamicTexture);
          })(),
        );
      } else if (isMaterialPropertyCanvas(property)) {
        texturePromises.push(
          (async (): Promise<void> => {
            await renderCanvas(property, dynamicTexture);
            await propertyFunction(nextMaterial, dynamicTexture);
          })(),
        );
      } else if (isMaterialPropertyFabric(property)) {
        // TODO: [CP-1237] This should probably take into account the
        // MeshConfig.isPickable as well.
        for (const mesh of boundMeshes) {
          mesh.isPickable = true;
        }

        texturePromises.push(
          (async (): Promise<void> => {
            await renderFabric(stateRef)(
              property,
              dynamicTexture,
              modelId,
              materialId,
            );
            await propertyFunction(nextMaterial, dynamicTexture);
          })(),
        );
      }
    }

    await Promise.all(texturePromises);

    const hasFlipX = 'flipX' in materialConfig;
    const hasFlipY = 'flipY' in materialConfig;
    if (hasFlipX || hasFlipY) {
      const textures = nextMaterial.getActiveTextures();

      for (const texture of textures) {
        if (texture instanceof Texture) {
          if (hasFlipX) {
            texture.uScale =
              Math.abs(texture.uScale) * (materialConfig.flipX ? -1 : 1);
            texture.uOffset = materialConfig.flipX ? 1 : 0;
          }

          if (hasFlipY) {
            texture.vScale =
              Math.abs(texture.vScale) * (materialConfig.flipY ? -1 : 1);
            texture.vOffset = materialConfig.flipY ? 1 : 0;
          }
        }
      }
    }

    if (originalMaterial) {
      material.dispose();
    }

    if (isMaterialReassignNeeded) {
      for (const mesh of boundMeshes) {
        mesh.material = nextMaterial;
      }
    }
  };

export default updateMaterial;
