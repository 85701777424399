import { GLOVES, GLOVE_ASSET_DICT, LEATHERS, WEBS } from '../../common/sheets'
import generateSnapshots, { Job, viewNames } from './generate'
import { getSku } from '../../common/meta'
import { PartialWithUndefined } from '@orangelv/utils'

const HEIGHT = 1024

const main = async ({
  gloveId,
}: PartialWithUndefined<{ gloveId: string }> = {}) => {
  const jobs: Job[] = []
  const jobIds: string[] = []
  for (const glove of GLOVES) {
    if (gloveId !== undefined && glove.id !== gloveId) continue

    const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]

    for (const leather of LEATHERS) {
      for (const viewName of viewNames) {
        if (!glove.limitations.leatherId[leather.id]) continue

        for (const web of WEBS) {
          if (!glove.limitations.webIds[web.id]) continue

          const jobId = `${glove.asset.gloveAssetId}-${web.id}-${viewName}`
          if (jobIds.includes(jobId)) continue
          jobIds.push(jobId)

          const filename = `${viewName}   ${glove.asset.gloveAssetId}   ${
            web.id
          }   ${getSku(leather, glove)}`

          const sourceSize = gloveAsset.props.imageDimensions[viewName]
          const height = HEIGHT
          const width = Math.round(
            (height / sourceSize.height) * sourceSize.width,
          )
          const size = {
            width,
            height,
          }

          jobs.push({
            filename,
            controlTreeChanges: [
              ['product.leather', leather.id],
              ['product.glove', glove.id],
              ['colors.web.style', web.id],
              ['colors.laces.fingerWeb', 'metallicGold'],
              ['colors.laces.heel', 'metallicSilver'],
              ['colors.binding', 'metallicGold'],
              ['colors.welting.back', 'metallicGold'],
              ['colors.welting.palm', 'metallicSilver'],
              ['colors.shellBack.leatherColor', 'navy'],
              ['colors.trim.color', 'navy'],
              ['colors.shellPalm', 'navy'],
              ['colors.web.color', 'navy'],
              ['colors.stamping', 'metallicGold'],
            ],
            viewName,
            size,
          })
        }
      }
    }
  }

  console.log('Jobs:')
  console.log(jobs)
  return generateSnapshots(jobs)
}

export default main
