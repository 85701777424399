import { type AbstractEngine, Matrix } from '@babylonjs/core';

import { getState } from '../state.js';
import type { Ref, State } from '../types.js';

const getTranslationValue = (
  value: number | undefined,
  engine: AbstractEngine,
): number => {
  if (value === undefined) return 0;

  return Number.isInteger(value) ? value / engine.getRenderWidth() : value;
};

const updateCameraProjectionMatrix = (stateRef: Ref<State>): void => {
  const { scene, props } = getState(stateRef);

  if (!scene) throw new Error('Logic error');
  if (!scene.activeCamera) return;

  const { x, y } = props.config.camera?.translateProjection ?? {};
  if (x === undefined && y === undefined) return;

  // Force recompute as to avoid multiplying the matrix more than once
  const projectionMatrix = scene.activeCamera.getProjectionMatrix(true);
  const engine = scene.getEngine();

  projectionMatrix.multiplyToRef(
    Matrix.Translation(
      getTranslationValue(x, engine),
      getTranslationValue(y, engine),
      0,
    ),
    projectionMatrix,
  );
};

export default updateCameraProjectionMatrix;
