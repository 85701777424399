import cn from 'classnames'
import { useState, type MouseEventHandler } from 'react'
import { push } from '../../../../platform/client/history'
import { navListRootIndices } from '../../../../platform/client/navigation'
import { t } from '../../../../platform/i18n'
import Button from './Button'
import Icon from './Icon'

import './MenuBar.css'

type MenuSubItem = {
  name: string
  effectiveId: string
}

type MenuItem = {
  name: string
  effectiveId: string
  children?: MenuSubItem[]
  siblingIndices?: number[]
}

type NavListItem = {
  name: string
  index: number
  effectiveId: string
  isAvailable: boolean
  childIndices: number[]
}

function getMenuItems(navList: Record<number, NavListItem>): MenuItem[] {
  return (
    navListRootIndices
      // first category is the "product finder" which needs to be treated differently
      .slice(1)
      .map((i) => navList[i])
      .filter((option) => option.isAvailable)
      .flatMap((category) =>
        category.childIndices.reduce((acc, childIndex) => {
          const option = navList[childIndex]

          if (!option.isAvailable) return acc

          if (option.childIndices.length === 0) {
            acc.push(option)
            return acc
          }

          const availableChildren = option.childIndices
            .map((index) => navList[index])
            .filter((child) => child.isAvailable)

          if (availableChildren.length === 0) {
            acc.push(option)
            return acc
          }

          const [firstChild, ...siblings] = availableChildren
          acc.push({
            ...option,
            effectiveId: firstChild.effectiveId,
            siblingIndices:
              siblings.length > 0 ? siblings.map((s) => s.index) : undefined,
          })

          return acc
        }, []),
      )
  )
}

function getProductFinder(navList) {
  const titleNode = navList[navList[navListRootIndices[0]].childIndices[0]]

  const subItems = titleNode.childIndices.map((i) => {
    const child = navList[i]
    return { name: child.name, id: child.id }
  })
  return [titleNode.name, subItems]
}

const ProductFinder = ({ navList, navigateToWizardStep, resetValues }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [title, subItems] = getProductFinder(navList)
  return (
    <div className="section has-sub-items">
      <div className="title has-icon" onClick={() => setIsOpen((o) => !o)}>
        <span>{title}</span>
        <Icon name="search" />
      </div>
      {isOpen && (
        <div className="sub-items">
          <div className="sub-item" onClick={resetValues}>
            {t('_rawlings:startOver')}
            <Icon name="start-over" />
          </div>
          {subItems.map((subItem) => (
            <div
              className="sub-item"
              onClick={() => navigateToWizardStep(subItem.id)}
            >
              {subItem.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const Menu = ({
  currentNavItem,
  navList,
  navigateToWizardStep,
  onCart,
  onSummary,
  product,
  resetValues,
  setMenuOpen,
}) => {
  const menuItems = getMenuItems(navList)
  return (
    <div className="menu">
      <div className="overlay" onClick={() => setMenuOpen(false)} />
      <div className="menu-container">
        <div className="menu-close" onClick={() => setMenuOpen(false)}>
          <Icon name="close" />
        </div>
        <div className="product-info">
          <div>
            <strong>{product.title}</strong>
          </div>
          <div> {product.priceWithCurrency}</div>
        </div>
        <div className="actions-container">
          <Button classMods={['hasIcon']} onClick={onCart}>
            <Icon name="cart" />{' '}
            {window?.serverConfig?.orderButtonText || t('_rawlings:addToCart')}
          </Button>
          <a className="purchase-summaryLink" onClick={onSummary}>
            <Icon name="info" /> {t('_rawlings:summary')}
          </a>
        </div>
        <div className="menu-items">
          <ProductFinder
            resetValues={resetValues}
            navList={navList}
            navigateToWizardStep={navigateToWizardStep}
          />
          {menuItems.map((item) => {
            const effectiveId = item.effectiveId
            return (
              <div className="section">
                <div
                  className={cn([
                    'title',
                    (
                      currentNavItem.effectiveId === effectiveId ||
                      item.siblingIndices?.includes(currentNavItem.index)
                    ) ?
                      'selected'
                    : '',
                  ])}
                  onClick={() => setMenuOpen(false)}
                >
                  <a href={`#${effectiveId}`}>{item.name}</a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

type Props = {
  currentNavItem: { id: string }
  navList: Record<number, NavListItem>
  navigateToWizardStep: (id: string) => void
  onCart: MouseEventHandler
  onSummary: MouseEventHandler
  product: any
  resetValues: () => void
  returnToSiteName?: string
  returnToSiteUrl?: string
  showMenuButtons?: boolean
}

const MenuBar = ({
  currentNavItem,
  navList,
  navigateToWizardStep,
  onCart,
  onSummary,
  product,
  resetValues,
  returnToSiteName,
  returnToSiteUrl,
  showMenuButtons,
}: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <div className="menu-bar">
        {returnToSiteUrl && (
          <a href={returnToSiteUrl} className="return-to-site-url">
            <div className="return-to-site-url-container">
              <span className="return-icon">
                <Icon name="arrow-back" inverted />
              </span>
              <span className="return-url">Back to {returnToSiteName}</span>
            </div>
          </a>
        )}
        {showMenuButtons && (
          <>
            <div className="product-info">
              <span>
                <strong>{product.title || ''}</strong>
              </span>
              {product.priceWithCurrency && (
                <span>
                  {' - '}
                  {product.priceWithCurrency.replace(' ', '')}
                </span>
              )}
            </div>
            <div className="button-container">
              <button
                className="menu-button"
                onClick={() => setMenuOpen((isOpen) => !isOpen)}
              >
                <div>Menu</div>
                <div className="icon-container">
                  <Icon name="bars" inverted />
                </div>
              </button>
              <button className="button add-to-cart-button" onClick={onCart}>
                Add to Cart
                <Icon name="cart-filled" />
              </button>
            </div>
          </>
        )}
      </div>
      {isMenuOpen && (
        <Menu
          currentNavItem={currentNavItem}
          navList={navList}
          setMenuOpen={setMenuOpen}
          navigateToWizardStep={(id) => {
            setMenuOpen(false)
            navigateToWizardStep(id)
          }}
          onCart={onCart}
          onSummary={(e) => {
            setMenuOpen(false)
            onSummary(e)
          }}
          product={product}
          resetValues={() => {
            resetValues()
            push({ path: '/', query: null })
            setMenuOpen(false)
          }}
        />
      )}
    </>
  )
}

export default MenuBar
