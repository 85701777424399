import {
  Constants,
  CubeTexture,
  HDRCubeTexture,
  type Scene,
} from '@babylonjs/core';

const canvasToBlob = async (canvas: HTMLCanvasElement): Promise<Blob> =>
  new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject();
      }
    }, 'image/png');
  });

const debugCanvas = async (canvas: HTMLCanvasElement): Promise<void> => {
  const blob = await canvasToBlob(canvas);
  const objectUrl = URL.createObjectURL(blob);
  window.open(objectUrl, '_blank');
};

const loadEnvironmentTexture = async (
  scene: Scene,
  url: string | readonly string[],
  hdrSize?: number,
): Promise<CubeTexture | HDRCubeTexture> => {
  if (typeof url === 'string') {
    if (url.endsWith('.hdr')) {
      return new Promise((resolve, reject) => {
        const texture = new HDRCubeTexture(
          url,
          scene,
          hdrSize ?? 1024,
          false,
          false,
          false,
          true,
          () => {
            resolve(texture);
          },
          () => {
            reject();
          },
        );
      });
    }

    return new Promise<CubeTexture>((resolve, reject) => {
      const texture = new CubeTexture(
        url,
        scene,
        undefined,
        false,
        undefined,
        () => {
          resolve(texture);
        },
        () => {
          reject();
        },
        Constants.TEXTUREFORMAT_RGBA, // cspell:disable-line
        true,
      );
    });
  }

  return new Promise<CubeTexture>((resolve, reject) => {
    const texture = new CubeTexture(
      // Seems like BabylonJS is using this internally to know whether the texture has been changed.
      JSON.stringify(url),
      scene,
      undefined,
      false,
      url as string[],
      () => {
        resolve(texture);
      },
      () => {
        reject();
      },
    );
  });
};

export { canvasToBlob, debugCanvas, loadEnvironmentTexture };
