import fp from 'lodash/fp.js'
import { useDispatch } from 'react-redux'
import {
  createStore,
  applyMiddleware,
  compose,
  UnknownAction,
  StoreEnhancer,
  Action,
} from 'redux'
import { thunk, ThunkDispatch } from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { concat } from 'redux-fp'
import localStoragePersistState from 'redux-localstorage'
import * as Sentry from '@sentry/browser'

// @ts-expect-error because ~c
import { getRecipeTitle, getSkuTitle, getIndexTitle } from '~c/common/meta'

// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import commonUpdater from './common/updater'
import { sendCopyToParentWindow } from './sendCopyToParentWindow'
import { loadChanges, updateChanges, updateTitle } from './common/actions'

const INITIAL_STATE = {
  history: {
    location: null,
    matches: null,
  },

  isErrorAlertOpen: false,
  debugViewerTabId: 'recipe',

  layoutMode: 'mobile',

  originValues: null,

  isRecipeLoading: false,
  isRecipeSaving: false,
  isPreviewsGenerating: false,
  isPreviewsUploading: false,

  isOrderingRecipe: false,

  isContactFormVisible: false,
}

export type AppDispatch = ThunkDispatch<any, any, Action>
export type AppGetState = () => any

export default function configureStore({
  updater,
  persist,
  initialState,
  controlTree,
}: {
  updater: any
  persist?: any
  initialState?: any
  controlTree: any
}) {
  const middleware = applyMiddleware(
    ({ dispatch }) =>
      (next) =>
      // TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error not sure how to fix right now
      (action: UnknownAction) => {
        const result = next(action)
        if (
          ['CHANGE', 'ADD_NODE', 'REMOVE_NODE', 'CANCEL'].includes(action.type)
        ) {
          dispatch(updateChanges(controlTree))
        }

        if (
          (action.type === 'NAVIGATED' && action.payload === 'POP') ||
          action.type === 'SET_ORIGIN_VALUES'
        ) {
          dispatch(
            loadChanges(
              controlTree,
              getRecipeTitle,
              getSkuTitle,
              getIndexTitle,
            ),
          )
        } else if (action.type === 'NAVIGATED') {
          dispatch(
            updateTitle(
              controlTree,
              getRecipeTitle,
              getSkuTitle,
              getIndexTitle,
            ),
          )
        }

        return result
      },

    () => (next) => (action: UnknownAction) => {
      if (
        window.parent !== window &&
        action.meta &&
        typeof action.meta === 'object' &&
        sendCopyToParentWindow in action.meta &&
        action.meta?.[sendCopyToParentWindow]
      ) {
        window.parent.postMessage(action, '*')
      }

      return next(action)
    },

    thunk,

    createLogger({
      duration: true,
      timestamp: false,
      collapsed: true,
      titleFormatter: (action, time, took) =>
        `${action.type} (in ${took.toFixed(2)} ms)`,
    }),

    () => (next) => (action: UnknownAction) => {
      Sentry.addBreadcrumb({
        category: 'reduxAction',
        message: action.type,
        data: action.payload as Sentry.Breadcrumb,
      })

      return next(action)
    },
  )

  const enhancer =
    persist ?
      (compose(
        middleware,
        localStoragePersistState(
          persist,
          // @ts-expect-error seems that this syntax hasn't been typed, or types have been generated for a newer version if this package (2nd argument is string)
          {
            // Support for nested paths.
            slicer: (paths: string[]) => (state: Record<string, unknown>) =>
              fp.reduce(
                (a, path) => fp.set(path, fp.get(path, state), a),
                {},
                paths,
              ),
          },
        ),
      ) as StoreEnhancer)
    : middleware

  const rootUpdater = concat(commonUpdater, updater)

  const store = createStore(
    (state, action) => rootUpdater(action)(state),
    { ...INITIAL_STATE, ...(initialState || {}) },
    enhancer,
  )

  return store as Omit<typeof store, 'dispatch' | 'getState'> & {
    dispatch: AppDispatch
    getState: AppGetState
  }
}

// https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
export const useAppDispatch: () => AppDispatch = useDispatch
