// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { LogoCategorieRow } from '../typings';

const data = [
  {
    "id": "standard",
    "name": "Standard",
    "order": 1,
    "sports": [
      "baseball",
      "softball"
    ]
  },
  {
    "id": "mlb",
    "name": "MLB",
    "order": 2,
    "sports": [
      "baseball"
    ]
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<LogoCategorieRow[]>> = data;

export type LogoCategorie = typeof typed[number];

export const LOGO_CATEGORIE_INDEX_KEY = "id";
export type LogoCategorieIndexKey = "id";
export type LogoCategorieId = LogoCategorie["id"];

let i = 0;
export const LOGO_CATEGORIE_DICT = {
  "standard": typed[i++],
  "mlb": typed[i++]
} as const;

export { typed as LOGO_CATEGORIES };
