// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { PricingSchemeRow } from '../typings';

const data = [
  {
    "id": "regular",
    "props": {
      "price": {
        "USD": {
          "kip": 499.95,
          "hoh": 399.95,
          "gxle": 229.95,
          "rla": 319.95,
          "rev1x": 549.95
        },
        "EUR": {
          "kip": 485,
          "hoh": 365,
          "gxle": 230,
          "rla": 285,
          "rev1x": 0
        },
        "CAD": {
          "kip": 599.95,
          "hoh": 499.95,
          "gxle": 329.95,
          "rla": 429.95,
          "rev1x": 649.95
        }
      },
      "sku": {
        "kip": "PROPRFCG",
        "hoh": "HOHCG",
        "gxle": "GXLECG",
        "rla": "RLASBCG",
        "rev1x": "REV1XCG"
      }
    },
    "name": t('rawlingsGloves:sheets.pricingSchemes.regular.name')
  },
  {
    "id": "training",
    "props": {
      "price": {
        "USD": {
          "kip": 399.95,
          "hoh": 299.95,
          "gxle": 0,
          "rla": 0,
          "rev1x": 0
        },
        "EUR": {
          "kip": 349.95,
          "hoh": 249.95,
          "gxle": 0,
          "rla": 0,
          "rev1x": 0
        },
        "CAD": {
          "kip": 599.95,
          "hoh": 449.95,
          "gxle": 0,
          "rla": 0,
          "rev1x": 0
        }
      },
      "sku": {
        "kip": "PROPRFTRCG",
        "hoh": "HOHTRCG",
        "gxle": "N/A",
        "rla": "N/A",
        "rev1x": "N/A"
      }
    },
    "name": t('rawlingsGloves:sheets.pricingSchemes.training.name')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PricingSchemeRow[]>> = data;

export type PricingScheme = typeof typed[number];

export const PRICING_SCHEME_INDEX_KEY = "id";
export type PricingSchemeIndexKey = "id";
export type PricingSchemeId = PricingScheme["id"];

let i = 0;
export const PRICING_SCHEME_DICT = {
  "regular": typed[i++],
  "training": typed[i++]
} as const;

export { typed as PRICING_SCHEMES };
