import { Vector3 } from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from './state.js';
import {
  LogLevel,
  type Ref,
  type RendererConfig,
  type State,
} from './types.js';

const updateMeshes =
  (stateRef: Ref<State>) =>
  (modelId: string, config: RendererConfig): void => {
    const { props } = getState(stateRef);

    const modelConfig = config.models[modelId];

    if (!modelConfig) {
      return;
    }

    const state = getState(stateRef);
    const modelState = state.models[modelId];

    ok(modelState);

    const { allMeshes } = modelState;

    if (modelConfig.meshes === undefined) {
      return;
    }

    for (const [meshId, meshConfig] of Object.entries(modelConfig.meshes)) {
      const meshes = allMeshes.filter(
        ({ id }) => id === meshId || id.startsWith(`${meshId}_primitive`),
      );

      ok(meshes.length > 0, `Mesh ${meshId} not found!`);

      for (const mesh of meshes) {
        const isVisible = meshConfig.isVisible ?? true;

        if (mesh.isEnabled(false) !== isVisible) {
          if (props.onLog) {
            props.onLog(LogLevel.Verbose, `Updating ${meshId} on ${modelId}`);
          }

          mesh.setEnabled(isVisible);
        }

        if (meshConfig.scaling) {
          const { x = 1, y = 1, z = 1 } = meshConfig.scaling;

          mesh.scaling = new Vector3(x, y, z);
        }

        if (meshConfig.materialId !== undefined) {
          const material = state.scene?.materials.find(
            (x) => x.id === meshConfig.materialId,
          );

          ok(
            material,
            `Material ${meshConfig.materialId} not found on model ${modelId}!`,
          );

          mesh.material = material;

          for (const childMesh of mesh.getChildMeshes()) {
            childMesh.material = material;
          }
        }
      }
    }
  };

export default updateMeshes;
