import type { Scene } from '@babylonjs/core';

import type { ModelState, Props, Ref, State } from './types.js';

const getState = (stateRef: Ref<State>): State => stateRef.current;

const setState =
  (stateRef: Ref<State>) =>
  (nextState: State): void => {
    stateRef.current = nextState;
  };

const updateState =
  (stateRef: Ref<State>) =>
  (stateUpdates: Partial<State>): void => {
    const previousState = getState(stateRef);
    const nextState = { ...previousState, ...stateUpdates };
    stateRef.current = nextState;
  };

const setProps =
  (stateRef: Ref<State>) =>
  (props: Props, previousProps: Props | undefined): void => {
    const nextState = getState(stateRef);
    nextState.props = props;
    nextState.previousProps = previousProps;
    stateRef.current = nextState;
  };

const setDigesting =
  (stateRef: Ref<State>) =>
  (isDigesting: boolean): void => {
    const nextState = getState(stateRef);
    nextState.isDigesting = isDigesting;
    stateRef.current = nextState;
  };

const setReady =
  (stateRef: Ref<State>) =>
  (isReady: boolean): void => {
    const nextState = getState(stateRef);
    nextState.isReady = isReady;
    stateRef.current = nextState;
  };

const setGeneratingPreviews =
  (stateRef: Ref<State>) =>
  (isGeneratingPreviews: boolean): void => {
    const nextState = getState(stateRef);
    nextState.isGeneratingPreviews = isGeneratingPreviews;
    stateRef.current = nextState;
  };

const setScenes =
  (stateRef: Ref<State>) =>
  (scene: Scene, emptyScene: Scene): void => {
    const nextState = getState(stateRef);
    nextState.scene = scene;
    nextState.emptyScene = emptyScene;
    stateRef.current = nextState;
  };

const setModelState =
  (stateRef: Ref<State>) =>
  (modelId: string, modelState?: ModelState): void => {
    const state = getState(stateRef);

    if (modelState) {
      state.models[modelId] = modelState;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- Models might need to be refactored to use Set or Map.
      delete state.models[modelId];
    }

    setState(stateRef)({
      ...state,
      models: state.models,
    });
  };

export {
  getState,
  setDigesting,
  setGeneratingPreviews,
  setModelState,
  setProps,
  setReady,
  setScenes,
  updateState,
};
