import i18next from 'i18next'

import customizer from '../customizer.cjs'

// For ESM/CJS portability
const { I18N_RESOURCES } = customizer

const DEFAULT_LOCALE = 'en-US'

let locale = DEFAULT_LOCALE

if (typeof window !== 'undefined') {
  const { serverConfig } = window
  if (serverConfig?.locale) {
    locale = serverConfig.locale
    console.log({ locale })
  }
}

i18next.init({
  resources: I18N_RESOURCES,
  lng: locale,
  fallbackLng: DEFAULT_LOCALE,
  keySeparator: false,
})

const t = i18next.t

export { i18next, t }
