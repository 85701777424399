import type { ArcRotateCamera } from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from '../state.js';
import type { Props, Ref, State } from '../types.js';
import updateCameraFovMode from './update-camera-fov-mode.js';
import updateCameraProjectionMatrix from './update-camera-projection-matrix.js';

const setCamera =
  (stateRef: Ref<State>, props: Props) =>
  (cameraId: string, modelIdOrUndefined?: string): void => {
    const { config } = props;

    let modelId: string | undefined;

    if (modelIdOrUndefined === undefined) {
      const ids = Object.keys(config.models);
      [modelId] = ids;
      if (modelId === undefined) throw new Error('Logic error');
      ok(
        ids.length === 1,
        "Cannot pick which model to use because there's more than one!",
      );
    } else {
      modelId = modelIdOrUndefined;
    }

    const state = getState(stateRef);

    ok(state.scene);

    const modelState = state.models[modelId];

    if (!modelState) {
      const { activeCamera } = state.scene;
      ok(activeCamera, 'No active camera');

      return;
    }

    const { assetContainer } = modelState;

    const camera = assetContainer.cameras.find(({ id }) => id === cameraId) as
      | ArcRotateCamera
      | undefined;

    ok(camera, `Camera ${cameraId} not found on model ${modelId}!`);

    const activeCamera = state.scene.activeCamera as ArcRotateCamera | null;

    ok(activeCamera);

    activeCamera.position = camera.globalPosition.clone();
    activeCamera.rotationQuaternion = camera.absoluteRotation.clone();
    activeCamera.fov = camera.fov;
    activeCamera.minZ = camera.minZ;

    updateCameraFovMode(stateRef)();
    updateCameraProjectionMatrix(stateRef);
  };

export default setCamera;
